$about-width: 380px;

.home-container {
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;

    .filter-container {
        position: sticky;
        top: 0;
        z-index: 11;
    }

    .home-header-container {
        display: flex;
        margin-bottom: -2px;
        p {
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            margin: 0;
            margin-right: 10px;
            cursor: pointer;
            transition: opacity 100ms ease-in-out;
            &:hover {
                opacity: 0.4;
            }
        }
        .selected {
            text-decoration: underline;
        }
    }
}

.about-container {
    position: fixed;
    pointer-events: none;
    right: 0;
    display: flex;
    flex-direction: column;
    width: $about-width;
    height: calc(var(--vh, 1vh) * 100);
    flex: 0 0 auto;
    padding: 12px;
    font-size: 16px;
    line-height: 23px;
    box-sizing: border-box;
    z-index: 10;

    p {
        margin: 0;
    }

    .about-content-container {
        flex: 1;
    }

    .about-content {
        padding-top: 18px;
    }

    .about-rights-container {
        flex: 0;
    }

    .about-rights {
        padding-top: 16px;
        font-size: 11px;
    }

    .small-caps {
        font-size: 15.7px;
    }
}

.project-summary-container {
    padding: 12px;
    padding-bottom: 0;
    overflow: scroll;
}

.table-container {
    width: calc(100% - $about-width + 24px);
    flex: 1;
    margin-left: -12px;
    padding-top: 10px;
    padding-bottom: 6px;
    box-sizing: border-box;

    .table-container-row {
        display: flex;
        flex-flow: row;
    }
}

.table-cell {
    margin: 12px;
    transition: opacity 100ms ease-in-out;

    img {
        width: 100%;
    }

    video {
        width: 100%;
    }

    .table-cell-info {
        margin-top: 8px;
        margin-left: 0;
        font-size: 11px;

        p {
            margin: 0;
            line-height: 12px;
        }
    }

    .cell-main-title {
        font-size: 11px;
        font-style: italic;
        color: blue;
    }

    &:hover {
        opacity: 0.6;
        cursor: pointer;
    }
}


@media screen and (max-width: 720px) {

    .home-container {
        display: block;
    }
    
    .about-container {
        position: relative;
        width: 100%;
        max-width: 100%;
        height: unset;
        pointer-events: unset;
    }

    .project-summary-container {
        padding-top: 0;
    }

    .table-container {
        width: calc(100% + 24px);
        padding-top: 0;
    }

    .table-cell {
        margin-bottom: 20px;
        &:hover {
            opacity: unset;
        }
    }
}