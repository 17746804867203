
body {
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    margin: 0;
    font-family: 'NeueHassUnica';
    font-size: 18px;
}

#root {
    width: 100%;
}

.app-container {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    overflow: scroll;
}

@font-face {
    font-family: 'Cheltenham';
    src: url('./assets/fonts/CheltenhamStd-LightItalic.otf');
    font-weight: lighter;
    font-style: normal;
}

@font-face {
    font-family: "EBGaramond";
    src: url("./assets/fonts/EBGaramond-VariableFont_wght.ttf") format("truetype-variations");
    font-weight: 1 999;
}

@font-face {
    font-family: 'Meno Bold';
    src: url('./assets/fonts/Meno\ Bold.ttf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Freight';
    src: url('./assets/fonts/Freight\ Text\ Book.otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'NeueHassUnica';
    src: url('./assets/fonts/NeueHaasUnicaPro-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'NeueHassUnica';
    src: url('./assets/fonts/NeueHaasUnicaPro-Italic.ttf');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'NeueHassUnica';
    src: url('./assets/fonts/NeueHaasUnicaPro-Bold.ttf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'NeueHassUnica';
    src: url('./assets/fonts/NeueHaasUnicaPro-BoldItalic.ttf');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'NeueHassUnica';
    src: url('./assets/fonts/NeueHaasUnicaPro-LightIt.ttf');
    font-weight: lighter;
    font-style: italic;
}

@font-face {
    font-family: 'NeueHassUnica';
    src: url('./assets/fonts/NeueHaasUnicaPro-Light.ttf');
    font-weight: lighter;
    font-style: normal;
}