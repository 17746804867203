body.preload * {
    animation-duration: 0s !important;
    -webkit-animation-duration: 0s !important;
    transition: background-color 0s, opacity 0s, color 0s, width 0s, height 0s, padding 0s, margin 0s !important;
}

/*********/
/* Fonts */
/*********/

.meno {
    font-family: "Meno Bold";
    font-weight: lighter;
}

/**************/
/* Typography */
/**************/

.p-18 {
    margin: 0;
}

.p-32 {
    font-size: 32px;
    font-weight: 500;
    line-height: 44px;
}

.p-48 {
    font-size: 48px;
    font-weight: 500;
    line-height: 60px;
    margin: 0;
}

.p-64 {
    font-size: 64px;
    font-weight: 500;
    line-height: 80px;
    margin: 0;
}

span.highlighted {
    position: relative;
    &::after {
        content: "";
        position: absolute;
        bottom: 15%;
        left: -0.1rem;
        background-color: yellow;
        width: calc(100% + 0.2rem);
        height: 40%;
        z-index: -1;
    }
}

/***********/
/* Spacing */
/***********/

.ml-32 {
    margin-left: 32px;
}

.mt-16 {
    margin-top: 16px;
}

.mt-8 {
    margin-top: 8px;
}

.pd-32 {
    padding: 32px;
}

.pd-16 {
    padding: 16px;
}

.pd-12 {
    padding: 12px;
}

/********/
/* Tags */
/********/

.tag {
    // padding: 0 3px;
    height: auto;
    width: auto;
    display:inline-block;
    border-bottom: 0.5px solid black;
    transition: border-color 0.2s ease-in-out;

    p {
        font-size: 12px;
        margin: 0;
    }
}

/***********/
/* Anchors */
/***********/

.regular-anchor {
    pointer-events: all;
    display: inline-block;
    position: relative;
    text-decoration: none;
    color: inherit;
    transition: opacity 100ms ease-in-out;
    font-style: italic;
    line-height: 1.1em;
    border-bottom: 1px solid blue;

    &:hover {
        opacity: 0.5;
    }
}
