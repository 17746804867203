$project-info-width: 720px;

.project-page-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    overflow: scroll;
    padding: 12px;
    box-sizing: border-box;
}

.project-header-container {
    font-size: 11px;
    line-height: 13px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;

    .return {
        text-decoration: underline;
        pointer-events: all;
        cursor: pointer;
        transition: opacity 100ms ease-in-out;

        &:hover {
            opacity: 0.4;
        }
    }

    .project-name {
        font-style: italic;
    }

    span {
        margin-right: 10px;
    }
}

.project-description {
    width: $project-info-width;
    max-width: 100%;
    font-size: 16px;
    line-height: 23px;
    margin-top: 14px;
    margin-bottom: 4px;
}

.project-summary {
    font-size: 11px;
    line-height: 13px;
}

.pictures-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 32px;
    padding-bottom: 32px;

    .project-picture {
        width: 100%;
    }

    .project-video {
        width: 100%
    }

    .res-content-container {
        position: relative;
        width: 900px;
        max-width: 100%;

        @media screen and (max-width: $project-info-width) {
            width: calc(100% + 24px);
            max-width: unset;
            margin-left: -12px;
        }
    }

    .video-container {
        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 6px;
            z-index: 1;
            background: rgba(0, 0, 0, 0.25);
        }
    }

    .presentation {
        box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.2);
    }
}

.inverted {
    color: #cccccc;
    background-color: #171717;
}
